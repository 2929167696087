<template>
    <!-- form -->
    <form class="validate-form">
    <!-- filter questions -->
    <base-card-plain :showHeader="false">

        <template #default>

            <div class="row">
                <h6 class="section-label mb-2">Create Job Ad</h6>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Allow team members with appropriate permissions to create job ads
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Allow team members with appropriate permissions to request job ads
                        </label>
                    </div>
                </div>

            </div>

            <div class="row mt-3">
                <h6 class="section-label mb-2">Create JD</h6>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Enable load existing JD when creating job ads by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch2" />
                        <label class="form-check-label" for="accountSwitch2">
                            Enable duplicate existing JD by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch3" />
                        <label class="form-check-label" for="accountSwitch3">
                            Enable create new JD form by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Allow change of default create job ad options while creating job ad
                        </label>
                    </div>
                </div>
            </div>


            <div class="row mt-3">
                <h6 class="section-label mb-2">Enumeration Option</h6>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Disclose enumeration by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch2" />
                        <label class="form-check-label" for="accountSwitch2">
                            Enable disclose enumeration by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch3" />
                        <label class="form-check-label" for="accountSwitch3">
                            Request applicants to quote enumeration by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch3" />
                        <label class="form-check-label" for="accountSwitch3">
                            Don't show enumeration information by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Allow change of default enumeration options while creating job ad
                        </label>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <h6 class="section-label mb-2">Filter Questions Option</h6>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Enable filter questions by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Enable select existing filter questions by default.
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Enable create new filter questions by default.
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Allow change of default filter question options while creating job ad
                        </label>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <h6 class="section-label mb-2">Auto screening option</h6>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Enable auto screening by default.
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Automatically reject applicants who fail screening by default.
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Allow change of default auto-screening options while creating job ad
                        </label>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <h6 class="section-label mb-2">Email templates</h6>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Allow team members with approptiate permissions to create email templates.
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Allow email template editing during job ad creation.
                        </label>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <h6 class="section-label mb-2">Accessments</h6>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Enable load existing accessment by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Enable load existing accessment config by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Enable create accessment by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Allow change of default accessment options while creating job ad
                        </label>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <h6 class="section-label mb-2">Publish</h6>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Enable advanced options by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Enable 3rd-party options by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Enable load existing 3rd party organizations by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Enable register new 3rd party organizations by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Allow change of default publish options while creating job ad
                        </label>
                    </div>
                </div>
            </div>


            <div class="row mt-3">
                <h6 class="section-label mb-2">Duration options</h6>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Set job ad duration to 7 days by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Set job ad duration to 10 days by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Set job ad duration to 14 days by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Set job ad duration to 20 days by default
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Allow change of default duration options while creating job ad
                        </label>
                    </div>
                </div>
            </div>


            <div class="row mt-3">
                <h6 class="section-label mb-2">Select applicant pool</h6>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Everyone
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Selected applicants
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Selected applicants in internal talent pool
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Selected applicants in all talent pool
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Allow change of default applicant options while creating job ad
                        </label>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <base-button btnColor="primary" >Save Information</base-button>
            </div>



        </template>
    </base-card-plain>
    <!-- / filter questions -->






    </form>
    <!--/ form -->
</template>

<script>
// import components
import BaseCardPlain from '@/components/ui/BaseCardPlain.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

export default{
    props:{
        useExistingJD:{
            default:false,
            type: Boolean,
            required: false
        }
    },
    components:{
        BaseCardPlain,
        BaseButton
    }
}


</script>
