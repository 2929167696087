<template>
    <!-- form -->
    <form class="validate-form">
    <!-- filter questions -->
    <base-card-plain :showHeader="false">

        <template #default>

            <div class="row">
                <h6 class="section-label mb-2">Subscriptions</h6>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Allow team members with approptiate permissions to view active subscriptions.
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Allow team members with approptiate permissions to view subscriptions history.
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch3" />
                        <label class="form-check-label" for="accountSwitch3">
                            Allow team members with approptiate permissions to pay/renew subscriptions.
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Auto renew subscriptions
                        </label>
                    </div>
                </div>

            </div>



            <div class="col-12">
                <base-button btnColor="primary" >Save Information</base-button>
            </div>



        </template>
    </base-card-plain>
    <!-- / filter questions -->






    </form>
    <!--/ form -->
</template>

<script>
// import components
import BaseCardPlain from '@/components/ui/BaseCardPlain.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

export default{
    props:{
        useExistingJD:{
            default:false,
            type: Boolean,
            required: false
        }
    },
    components:{
        BaseCardPlain,
        BaseButton
    }
}


</script>
