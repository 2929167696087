<template>
    <!-- form -->
    <form class="validate-form">
    <!-- filter questions -->
    <base-card-plain :showHeader="false">

        <template #default>

            <div class="row">
                <h6 class="section-label mb-2">Login</h6>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Enable two factor mail authentication.
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch3" />
                        <label class="form-check-label" for="accountSwitch3">
                            Enable to factor phone authentication.
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch2" />
                        <label class="form-check-label" for="accountSwitch2">
                            Enable two factor mail & phone authentication
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch2" />
                        <label class="form-check-label" for="accountSwitch2">
                            Enable two factor authy authentication
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch2" />
                        <label class="form-check-label" for="accountSwitch2">
                            Enable puzzle authentication authentication
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch2" />
                        <label class="form-check-label" for="accountSwitch2">
                            Enable pattern matching authentication
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch2" />
                        <label class="form-check-label" for="accountSwitch2">
                            Allow team members with approptiate permissions to update this login settings.
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch2" />
                        <label class="form-check-label" for="accountSwitch2">
                            Allow team members with to update this login settings for their individual account.
                        </label>
                    </div>
                </div>

            </div>

            <div class="row mt-3">
                <h6 class="section-label mb-2">Update account email & password</h6>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch1" />
                        <label class="form-check-label" for="accountSwitch1">
                            Enable two factor mail authentication.
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" checked id="accountSwitch3" />
                        <label class="form-check-label" for="accountSwitch3">
                            Enable to factor phone authentication.
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch2" />
                        <label class="form-check-label" for="accountSwitch2">
                            Enable two factor mail & phone authentication
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch2" />
                        <label class="form-check-label" for="accountSwitch2">
                            Enable two factor authy authentication
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch2" />
                        <label class="form-check-label" for="accountSwitch2">
                            Enable puzzle authentication authentication
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch2" />
                        <label class="form-check-label" for="accountSwitch2">
                            Enable pattern matching authentication
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch2" />
                        <label class="form-check-label" for="accountSwitch2">
                            Allow team members with approptiate permissions to update this change email & password settings.
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="accountSwitch2" />
                        <label class="form-check-label" for="accountSwitch2">
                            Allow team members to update this change email & password settings for their individual account.
                        </label>
                    </div>
                </div>

            </div>



            <div class="col-12">
                <base-button btnColor="primary" >Save Information</base-button>
            </div>



        </template>
    </base-card-plain>
    <!-- / filter questions -->






    </form>
    <!--/ form -->
</template>

<script>
// import components
import BaseCardPlain from '@/components/ui/BaseCardPlain.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

export default{
    props:{
        useExistingJD:{
            default:false,
            type: Boolean,
            required: false
        }
    },
    components:{
        BaseCardPlain,
        BaseButton
    }
}


</script>
